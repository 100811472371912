import { createApp } from 'vue';
import duConfirmDialogComponent from './duConfirmDialogComponent.vue';

const DuConfirmDialog = new class {
  create = function ({
     title= '',
     description= '',
     onConfirm= () => {},
     onCancel = () => {},
     confirmColor= '#000',
     descriptionColor= '#444',
     confirmText= '',
     cancelText= '',
     //id: Math.floor(Math.random() * 1000000) + 1,
   }) {

    if(!document.getElementById("confirmDialogsRoot")) {
      const comp = duConfirmDialogComponent;
      const rootElement = document.createElement('div');
      rootElement.setAttribute("id", "confirmDialogsRoot");
      document.body.appendChild(rootElement);
      createApp(comp).mount(rootElement);
    }

    const creationEvent = new CustomEvent('createConfirmDialog');
    const _data = arguments[0];
    _data.id = Math.floor(Math.random() * 1000000) + 1;
    creationEvent.data = _data;
    document.dispatchEvent(creationEvent);

  }
}

export default DuConfirmDialog;
