<template>
  <div class="commentCel">
    <div class="userThumb" :style="getUserThumbStyle()"></div>
    <div class="infoSide">
      <div class="nameAndDate">
        <div class="name">{{ getModelData().name }}</div>
        <div class="timeAbout"><TimeAbout :time-stamp="state.data.date"/></div>
      </div>
      <RatingBar :icons-width="12" :init-rate="state.data.rating" :readOnly="true" class="commentRating"/>
      <div class="commentContent">{{ getModelData().comment }}</div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import RatingBar from "../RatingBar";
import TimeAbout from "./TimeAbout";

export default {
  name: "OutComment",
  components: {TimeAbout, RatingBar},
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  setup(props) {
    const state = reactive({
      data: props.data
    });
    const getModelData = () => {
      const _data = {
        thumb: null
      };
      if(state.data.is_guest) {
        _data.name = state.data.anonymos_name;
        _data.rating = state.data.rating;
      }
      _data.comment = state.data.comment;
      return _data;
    }
    const getUserThumbStyle = () => {
      return {
        backgroundImage: `url('${require('@/assets/images/defaultUserThumb.png')}')`,
        'background-position': 'center 170%',
        'background-size': '90%',
      };
    }
    return {
      state,
      getModelData,
      getUserThumbStyle,
    }
  }
}
</script>

<style scoped>
.commentCel{
  width: available;
  padding: 10px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: start;
}
.commentCel .userThumb{
  width: 60px;
  height: 60px;
  background: #ccc;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.commentCel .infoSide{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.commentCel .nameAndDate{
  gap: 10px;
  display: flex;
  flex-direction: row;
  color: #fff;
}

.commentCel .nameAndDate .name{
  font-size: 16px;
}

.commentCel .nameAndDate .timeAbout{
  font-size: 13px;
  color: #ccc;
}

.commentCel .commentContent{
  font-size: 17px;
  color: #fff;
  margin-top: 5px;
}

.commentRating{
  gap: 0;
  margin-top: 5px;
}
</style>