<template>
  <div class="outCatControlPanelCell" v-show="!state.isRemoved">
    <div class="catImage" :style="getCatThumbStyle()"></div>
    <div class="catInfo">
      <div class="catTitle">{{ state.data.title }}</div>
    </div>
    <div class="catOptionButton" @click="(e) => showOptions(e)">
      <IconsWorker icon="menuF" width="20"/>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import IconsWorker from "../IconsWorker";
import DuContextMenu from "../plugins/duContextMenu/duContextMenu";
import router from "../../router";
import FixedNotification from "../plugins/fixedNotifications/FixedNotification";
import myAccountAsyncStore from "../../store/myAccountAsyncStore";
import DuConfirmDialog from "../plugins/duConfirmDialog/duConfirmDialog";
import AjaxWorker from "../../helpers/AjaxWorker";

export default {
  name: "OutCatControllPanel",
  components: {IconsWorker},
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const state = reactive({
      isRemoved: false,
      data: props.data,
    });
    const showOptions = (e) => {
      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "delete"){
            DuConfirmDialog.create({
              title: "هل تريد حذف هذا القسم ؟",
              description: "",
              confirmColor: "red",
              confirmText: "حذف",
              cancelText: "لا تراجع",
              onConfirm: () => {
                state.isRemoved = true;
                FixedNotification.create({
                  decription: "تم حذف القسم",
                  icon: "delete",
                  color: 'green',
                  hideAfter: 3000
                });
                new AjaxWorker().request('/transactions.php?action=removeCat', {
                  id: state.data.id,
                }).then((res) => {
                  console.log(state.data.id);
                  console.log(res);
                });
              }
            });
          } else if(key === "edit"){
            await router.push('/controlPanel/editCat/' + state.data.id);
          }
        },
        options: [
          { title: 'حذف', key: 'delete', icon: 'delete' },
          { title: 'تعديل', key: 'edit', icon: 'edit' },
        ],
      });
    }
    const getCatThumbStyle = () => {
      const _style = {};
      _style.backgroundImage = "url('" + new AjaxWorker().url + "/media/" + state.data.image + "')";
      return _style;
    }
    return {
      state,
      getCatThumbStyle,
      showOptions,
    }
  }
}
</script>

<style scoped>
  .outCatControlPanelCell{
    display: flex;
    flex-direction: row;
    width: 96%;
    padding: 20px 2%;
    border-top: 1px solid #eee;
    gap: 10px;
    align-items: center;
  }

  .catImage{
    width: 60px;
    height: 60px;
    background-color: #ccc;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .catInfo{
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .catTitle{
    font-size: 17px;
    font-weight: bold;
  }
  .catOptionButton{
    width: 20px;
    height: 20px;
    padding: 8px;
    background-color: #ccc;
    border-radius: 100px;
    cursor: pointer;
  }
  .catOptionButton:hover{
    opacity: 0.8;
  }
  .catOptionButton:active{
    opacity: 0.2;
  }
</style>