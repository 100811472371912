<template>
  <div class="ApplyControlPanelCell" v-if="!state.isRemoved">
    <div class="interface" :style="getMainCelStyle()">
      <div class="coverImage" :style="{ backgroundImage: `url('${getPhotos()[0]}')`, }" @click="open()"></div>
      <div class="id">{{ state.data.id }}</div>
      <div class="nameAndCity" @click="open()">
        <div class="name">{{ state.data.name }}</div>
        <div class="city">{{ state.data.areaTitle }}</div>
      </div>
      <div class="optionsMenuAntState">
        <div class="optionsButton" @click="(e) => showOptionsMenu(e)">
          <IconsWorker icon="menuF" width="20"/>
        </div>
        <DuInput
            label=""
            type="toggle"
            :value="state.data.is_eccept"
            @input="(e) => changeApplyState(e)"
        />
      </div>
    </div>
    <div class="content" v-if="state.isOpen">
      <div class="infoCel" v-for="info in state.infos" :key="info.key">
        <IconsWorker :icon="info.icon" width="15"/>
        <div class="key">{{ info.key }}</div>
        <div class="value" @click="info.onClick()">{{ truncate(info.value) }}</div>
      </div>
      <div class="photos">
        <DuPhotoViewer v-for="photo in getPhotos()" :thumb="createPhotoLink(photo, 80, 80)" :fullImage="createPhotoLink(photo)" width="80" height="80" :listOfLinks="getListOfLinks()"/>
      </div>
    </div>
    <AddOrEditApplyLightBox
        v-if="state.isEditDialogOpen"
        :on-done="(data) => onEditDone(data)"
        :on-cancel="() => onCloseEditLightBox()"
        :init-data="{...state.data}"
    />
  </div>

</template>

<script>
import {onMounted, reactive} from "vue";
import IconsWorker from "../IconsWorker";
import AjaxWorker from "../../helpers/AjaxWorker";
import DuPhotoViewer from "../DuPhotoViewer";
import DuInput from "../DuInput";
import DuContextMenu from "../plugins/duContextMenu/duContextMenu";
import router from "../../router";
import FixedNotification from "../plugins/fixedNotifications/FixedNotification";
import myAccountAsyncStore from "../../store/myAccountAsyncStore";
import DuConfirmDialog from "../plugins/duConfirmDialog/duConfirmDialog";
import AddOrEditApplyLightBox from "../../views/AddOrEditApplyLightBox";

export default {
  name: "ApplyControlPanelCell",
  components: {AddOrEditApplyLightBox, DuInput, DuPhotoViewer, IconsWorker},
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  setup(props) {
    const state = reactive({
      data: props.data,
      isOpen: false,
      infos: [],
      isEccept: props.data.is_eccept === 1,
      isRemoved: false,
      isEditDialogOpen: false,
    });
    const createInfoCells = () => {
      state.infos = [];
      state.infos.push({ key: 'الاسم', value: state.data["name"], icon: 'idF'});
      state.infos.push({ key: 'اسم المكان', value: state.data["place_name"], icon: 'tagF'});
      state.infos.push({ key: 'البريد', value: state.data["email"], icon: 'emailF'});
      state.infos.push({ key: 'المنطقة', value: state.data["area"], icon: 'locationMarkerF'});
      state.infos.push({ key: 'انستجرام', value: state.data["instagram"], icon: 'instagramF', onClick: ()=> {
          window.open("https://instagram.com/" + state.data["instagram"], '_blank').focus();
      }});
      state.infos.push({ key: 'رابط الخرائط', value: state.data["google_map_url"], icon: 'googleMapsF', onClick: ()=> {
          window.open(state.data["google_map_url"], '_blank').focus();
      }});
      state.infos.push({ key: 'التصريح', value: state.data["is_need_permission"] === 1 ? 'يتطلب' : 'لا يتطلب', icon: 'roundLockF'});
      state.infos.push({ key: 'تاريخ الطلب', value: state.data["date"], icon: 'clockF'});
    }
    onMounted(() => {
      createInfoCells();
    });
    const getMainCelStyle = () => {
      let _style = {};
      if(state.isEccept) {
        _style.background = "rgba(255, 0, 83, 0.01)";
      }
      return _style;
    }
    const open = () => {
      state.isOpen = !state.isOpen;
    }
    const getPhotos = () => {
      const rootUrl = new AjaxWorker().url + '/media/';
      const photos = [];
      props.data.photos.split(',').forEach((photo) => {
        photos.push(
            rootUrl + photo
        );
      });
      return photos;
    }
    const changeApplyState = (e) => {
      new AjaxWorker().request("/transactions.php?action=acceptApply&id=" + state.data.id + "&state=" + e.value).then((response) => {
        console.log(response);
      });
    };

    const refreshData = () => {
      new AjaxWorker().request('/transactions.php?action=getItems', {
        id: state.data.id
      }).then((res) => {
        if(res.state) {
          state.data = res.data[0];
        }
      });
    }

    const onCloseEditLightBox = () => {
      state.isEditDialogOpen = false;
    }
    const onEditDone = () => {
      state.isEditDialogOpen = false;
      refreshData();
    }
    const showOptionsMenu = (e) => {
      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "edit") {

            state.isEditDialogOpen = true;

          } else if(key === "showComments") {

            router.push("/controlPanel/comments?postId=" + state.data.id);

          } else if(key === "delete") {

            DuConfirmDialog.create({
              title: "هل تريد حذف هذا المكان ؟",
              description: "",
              confirmColor: "red",
              confirmText: "حذف",
              cancelText: "لا تراجع",
              onConfirm: () => {
                state.isRemoved = true;
                FixedNotification.create({
                  decription: "تم حذف المكان",
                  icon: "delete",
                  color: 'green',
                  hideAfter: 3000
                });
                new AjaxWorker().request('/transactions.php?action=deleteApply', {
                  id: state.data.id,
                }).then((res) => {
                  console.log(state.data.id);
                  console.log(res);
                });
              }
            });

          }
        },
        options: [
          { title: 'حذف', key: 'delete', icon: 'delete' },
          { title: 'تعديل', key: 'edit', icon: 'edit' },
          { title: 'عرض التعليقات', key: 'showComments', icon: 'comment' },
        ],
      });
    }

    const createPhotoLink = (link, width=null, height=null) => {
      if(width !== null && height !== null) {
        link = link + '?w=' + width.toString();
      } else {
        link = link + "?w=900";
      }
      return link;
    }

    const getListOfLinks = () => {
      const links = [];
     getPhotos().forEach((item, index) => {
        links.push({
          fullImage:  item + "?w=900",
          thumb: item + "?w=100",
          id: index
        });
      });
      return links;
    }

    const truncate = (input) => input.length > 20 ? `${input.substring(0, 20)}...` : input;

    return {
      showOptionsMenu,
      onEditDone,
      changeApplyState,
      onCloseEditLightBox,
      getMainCelStyle,
      getPhotos,
      createPhotoLink,
      getListOfLinks,
      open,
      refreshData,
      state,
      truncate,
    }
  }
}
</script>

<style scoped>
.ApplyControlPanelCell{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: start;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.interface{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
}
.coverImage{
  width: 70px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.id{
  padding: 5px;
  background: #eee;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.nameAndCity{
  display: flex;
  flex-direction: column;
  justify-items: center;
  flex: 1;
}
.name{
  font-size: 16px;
  margin-bottom: 5px;
  color: #222;
}
.city{
  font-size: 14px;
  color: #555;
}
.content{
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 5%;
  width: 90%;
}
.content .infoCel{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  text-align: start;
  align-items: center;
  padding: 10px 0;
}
.content .infoCel .key{
  font-size: 15px;
  color: #555;
}
.content .infoCel .value{
  font-size: 15px;
  color: #111;
  flex: 1;
  cursor: pointer;

}

.photos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.optionsMenuAntState{
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
}
.optionsButton{
  border-radius: 100%;
  padding: 7px;
  background: #ccc;
  margin: 0 10px;
  width: 20px;
  height: 20px;
}

.optionsButton:hover{ opacity:  0.8; }
.optionsButton:active{ opacity:  0.5; }

</style>