<template>
  <div :class="getClassesList()">
    <div class="id">{{ state.data.id }}</div>
    <div class="thumb" :style="getThumb()"></div>
    <div class="infoSide">
      <div class="name">{{ state.data.name }}</div>
      <div class="username">@{{ state.data.username }}</div>
    </div>
    <div class="optionMenu" @click="(e) => showOptions(e, state.data.id)"><IconsWorker icon="menuF" :width="15"/></div>
  </div>
  <EditAccountLightBox :is-open="state.isEditLightBoxOpen" :on-done="onEditingDone"  :on-close="closeEditLightBox" :the-key="state.selectedEditKey" :userId="state.selectedEditId"/>
</template>

<script>
import {reactive} from "vue";
import AjaxWorker from "../../helpers/AjaxWorker";
import IconsWorker from "../IconsWorker";
import DuContextMenu from "../plugins/duContextMenu/duContextMenu";
import DuConfirmDialog from "../plugins/duConfirmDialog/duConfirmDialog";
import FixedNotification from "../plugins/fixedNotifications/FixedNotification";
import router from "../../router";
import myAccountAsyncStore from "../../store/myAccountAsyncStore";
import EditAccountLightBox from "../EditAccountLightBox";

export default {
  name: "DashboardUser",
  components: {EditAccountLightBox, IconsWorker},
  props: {
    data: {},
  },
  setup(props) {
    const state = reactive({
      data: props.data,
      isEditLightBoxOpen: false,
      selectedEditKey: null,
      selectedEditId: null,
      isLoadingData: false,
    });
    const getThumb = () => {
      const _style = {
        backgroundImage: `url('${require('@/assets/images/defaultUserThumb.png')}')`,
        'background-position': 'center',
      };
      if(state.data.thumb !== '') {
        const _thumb = new AjaxWorker().url + "/media/" + state.data.thumb + "?w=80";
        _style.backgroundImage = `url('${_thumb}')`;
      }
      return _style;
    }

    const loadData = () => {
      state.isLoadingData = true;
      new AjaxWorker().request("/transactions.php?action=getUsers&id=" + state.data.id).then(res => {
        if(res === "NOINTERNETCONNECTION") {
          setTimeout(() => {
            loadData();
          }, 3000);
        } else {
          if(res.state) {
            state.isLoadingData = false;
            state.data = res;
          } else {
            FixedNotification.create({
              decription: 'حذث خطاء غير معروف',
              icon: 'errorF',
              color: 'red',
              hideAfter: 3000
            });
          }
        }
      });
    }

    const showEditOptions = (e) => {

      let _options = [
        { title: 'تعديل البريد الإلكتروني', key: 'email', icon: 'email' },
        { title: 'تعديل اسم المستخدم', key: 'username', icon: 'user' },
        { title: 'تعديل كلمة المرور', key: 'password', icon: 'roundLock' },
        { title: 'تعديل الاسم', key: 'name', icon: 'id' },
      ];

      DuContextMenu.create({
        event: e,
        options: _options,
        onSelect: async (key) => {
          state.isEditLightBoxOpen = true;
          state.selectedEditKey = key;
        },
      });

    }

    const showOptions = (e, id) => {
      let _options = [
        { title: 'تعديل', key: 'edit', icon: 'edit' },
      ];


      if(getMyData().id !== state.data.id) {
        if(state.data.is_blocked) {
          _options.push({ title: 'فك حظر', key: 'unBlock', icon: 'key' });
        } else if(!state.data.is_blocked) {
          _options.push({ title: 'فرض حظر', key: 'block', icon: 'block' });
        }

      }

      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "block" || key === "unBlock"){
            let _message = "هل تريد حظر المستخدم من دخول النظام ؟";
            let _color = "red";
            let _buttonText = "حظر";
            if(key === "unBlock") {
              _message = "هل تريد فك الحظر؟ سيسمح لهذا المستخدم؟ بمتابعة استعمال النظام بصورة طبيعية";
              _color = "green";
              _buttonText = "فك الحظر";
            }
            DuConfirmDialog.create({
              title: _message,
              description: "",
              confirmColor: _color,
              confirmText: _buttonText,
              cancelText: "لا تراجع",
              onConfirm: () => {
                FixedNotification.create({
                  decription: state.data.is_blocked? "تم فك الحظر" : "تم حظر المستخدم",
                  icon: "delete",
                  color: 'green',
                  hideAfter: 3000
                });
                const _action = state.data.is_blocked ? 'unBlockUserFromSystem' : 'blockUserFromSystem';
                new AjaxWorker().request('/transactions.php?action=' + _action, {
                  id: state.data.id,
                }).then((res) => {
                  console.log(state.data.id);
                  console.log(res);
                });
                state.data.is_blocked = !state.data.is_blocked;
              }
            });
          } else if(key === "edit"){
            state.selectedEditId = id;
            setTimeout(() => {
              showEditOptions(e);
            }, 100);
          }
        },
        options: _options,
      });
    }

    const getMyData = () => {
      return myAccountAsyncStore.getters.getData;
    }

    const closeEditLightBox = () => {
      state.isEditLightBoxOpen = false;
    }
    const onEditingDone = () => {
      state.isEditLightBoxOpen = false;
      loadData();
    }

    const getClassesList = () => {
      let _list = ['dashboardUserCel'];
      if(state.isLoadingData) {
        _list.push('loadingUserData');
      }
      return _list;
    }
    return {
      onEditingDone,
      showOptions,
      closeEditLightBox,
      getMyData,
      getThumb,
      state,
      getClassesList,
    }
  }
}
</script>

<style scoped>
  .dashboardUserCel{
    width: available;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px;
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 0 0 5px #eee;
    border-radius: 10px;
    align-items: center;
  }
  .dashboardUserCel .id{
    min-width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 100px;
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
  }
  .dashboardUserCel .thumb{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .dashboardUserCel .infoSide{
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .dashboardUserCel .infoSide .name{
    font-size: 16px;
    color: #555;
  }
  .dashboardUserCel .infoSide .username{
    font-size: 14px;
    color: #111;
  }
  .dashboardUserCel .optionMenu{
    padding: 10px;
    background-color: #fff;
    border-radius: 100px;
    box-shadow: 0 0 5px #999;
    cursor: pointer;
  }
  .dashboardUserCel .optionMenu:hover{opacity: 0.7;}
  .dashboardUserCel .optionMenu:active{opacity: 0.4;}
  
  .loadingUserData{
    opacity: 0.2;
    pointer-events: none;
  }
</style>