<template>
  <div class="duList">
    <div class="loading" v-if="state.isLoading">
      <IconsWorker icon="loading" :width="40"/>
    </div>
    <div class="duListContent" :style="getListStyle()" v-else>
      <ApplyControlPanelCell v-for="(data, index) in state.data" :key="`applysControlPanel${index}`" :data="data" v-if="type === 'applysControlPanel'"/>
      <ApplyCell v-for="(data, index) in state.data" :key="'applyCell_' + index" :data="data" v-else-if="type === 'applys'"/>
      <OutComment v-for="(data, index) in state.data" :key="`comment${index}`" :data="data" v-else-if="type === 'comments'"/>
      <OutCommentControlPanel v-for="(data, index) in state.data" :key="`cpComment${index}`" :data="data" v-else-if="type === 'commentsControlPanel'"/>
      <OutCatControllPanel v-for="(data, index) in state.data" :key="`cpCat${index}`" :data="data" v-else-if="type === 'catsControlPanel'" />
      <OutCat v-for="(data, index) in state.data" :key="`cat${index}`" :data="data" v-else-if="type === 'cats'" />
      <ContactUsMessagesCp v-for="(data, index) in state.data" :key="`CUCP${index}`" :data="data" v-else-if="type === 'contactUsMessagesCp'" />
      <DashboardUser v-for="(data, index) in state.data" :key="`cpUser${index}`" :data="data" v-else-if="type === 'dashboardUsers'" />
    </div>

    <slot name="noResults" v-if="!state.isLoading && state.data.length < 1"></slot>

    <div class="loadingMore" v-if="state.isLoadingMore">
      <IconsWorker icon="loading" :width="30"/>
    </div>
  </div>
</template>

<script>
import ApplyControlPanelCell from "./fragments/ApplyControlPanelCell";
import {onBeforeMount, onMounted, onUnmounted, reactive, watch} from "vue";
import AjaxWorker from "../helpers/AjaxWorker";
import IconsWorker from "./IconsWorker";
import ApplyCell from "./fragments/ApplyCell";
import OutComment from "./fragments/OutComment";
import OutCommentControlPanel from "./fragments/OutCommentControlPanel";
import OutCatControllPanel from "./fragments/OutCatControllPanel";
import OutCat from "./fragments/OutCat";
import ContactUsMessagesCp from "./fragments/ContactUsMessagesCp";
import DashboardUser from "./fragments/DashboardUser";
export default {
  name: "DuList",
  components: {
    DashboardUser,
    ContactUsMessagesCp,
    OutCat,
    OutCatControllPanel, OutCommentControlPanel, OutComment, ApplyCell, IconsWorker, ApplyControlPanelCell},
  props: {
    type: {
      type: String,
    },
    exstraParameters: {
      type: Object,
      default: {}
    },
    style: {
      type: Object,
    },
    scrollBody: {
      type: Element,
      default: null,
    },
    limit: {
      default: 10,
      type: Number,
    },
  },
  setup(props) {
    const state = reactive({
      data: [],
      isLoading: true,
      isLoadingFail: false,
      isLoadingMore: false,
      isLoadingRefreshing: false,
      lastIdFetched: null,
      limit: props.limit,
      isEndOfResults: false,
      offset: 0,
    });
    const getData = async () => {

      let _action = "";
      const params = props.exstraParameters;
      if(props.type === "applysControlPanel" || props.type === "applys") {
        _action = "getItems";
        if(props.exstraParameters.orderBy !== undefined && props.exstraParameters.orderBy === 'mostRating') {
          params.offset = state.offset;
        }
      } else if(props.type === "comments" || props.type === "commentsControlPanel") {
        _action = "getComments";
      } else if(props.type === "cats" || props.type === "catsControlPanel") {
        _action = "getCats";
      } else if(props.type === "contactUsMessagesCp") {
        _action = "getContactUsMessages";
      } else if(props.type === "dashboardUsers") {
        _action = "getUsers";
      }

      if(state.lastIdFetched !== null && state.lastIdFetched !== 'null') {
        params.lastIdFetched = state.lastIdFetched;
      }


      params.limit = state.limit;

      if(state.data.length === 0) {
        state.isLoading = true;
      } else {
        state.isLoadingMore = true;
      }


      await new AjaxWorker().request('/transactions.php?action=' + _action, params).then((res) => {
        console.log('/transactions.php?action=' + _action);
        console.log(params);
        console.log(res);
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getData();
          }, 2000);
        } else {
          state.data = state.data.concat(res.data);
          if(state.data.length > 0)
            state.lastIdFetched = state.data[state.data.length-1].id;

          if(state.isLoadingMore && res.data < 10) {
            state.isEndOfResults = true;
          }
          state.isLoading = false;
          state.isLoadingMore = false;
          state.isLoadingRefreshing = false;
          state.offset = state.data.length;
        }

        console.log(state.data);
      });
    }
    const getListStyle = () => {
      let _style = {
        display: 'flex',
        flexDirection: 'column',
      };
      if(props.style !== undefined) {
        _style = props.style;
      }
      return _style;
    }
    watch(() => JSON.stringify(props.exstraParameters), () => {
      state.lastIdFetched = null;
      state.data = [];
      state.offset = 0;
      state.isLoading = true;
      state.isEndOfResults = false;
      getData();
    }, );
    watch(() => (props.type), () => {
      getData();
    });
    const scrollListener = (e) => {
      const scrollTop = props.scrollBody.scrollTop;
      const scrollHeight = props.scrollBody.scrollHeight; // added
      const offsetHeight = props.scrollBody.offsetHeight;
      // var clientHeight = document.getElementById('box').clientHeight;
      const contentHeight = scrollHeight - offsetHeight; // added
      if (contentHeight <= (scrollTop + 50)) // modified
      {
        // Now this is called when scroll end!
        if(state.data.length > 0 && !state.isLoading && !state.isLoadingMore && !state.isLoadingRefreshing) {
          if(!state.isEndOfResults) {
            getData();
          }
        }
      }
    }
    onMounted(() => {
      state.lastIdFetched = null;
      state.data = [];
      state.isEndOfResults = false;
      getData();
      if(props.scrollBody !== null)
        props.scrollBody.addEventListener("scroll", scrollListener);
    });
    onUnmounted(() => {
      if(props.scrollBody !== null)
        props.scrollBody.removeEventListener("scroll", scrollListener);
    });
    return {
      state,
      getListStyle,
    };
  }
}
</script>

<style scoped>
.duList{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.loading{
  width: 40px;
  height: 40px;
  place-self: center;
  display: flex;
  margin-top: 50px;
}
.loadingMore{
  width: 30px;
  height: 30px;
  place-self: center;
  display: flex;
  margin-top: 5px;
}
.duListContent{
  display: flex;
  flex-direction: column;
  width: available;
}
</style>