<template>
  <RouterLink :to="'/placeInfo/' + state.data.id" class="ApplyCell">

    <div class="thumb" :style="{ backgroundImage: `url('${createPhotoLink(state.photos[0], 200, 200)}')` }"></div>
    <div class="informationSide">
      <div class="placeName">{{state.data.place_name}}</div>
      <RatingBar :init-rate="state.data.rating" :read-only="true" icons-width="15" class="ratingArea"/>
    </div>
    <div class="catAndArea">
      <div class="cat">{{ state.data.catTitle }}</div>
      <div class="area">{{ state.data.areaTitle }}</div>
    </div>
  </RouterLink>
</template>

<script>
import {onMounted, reactive} from "vue";
import IconsWorker from "../IconsWorker";
import AjaxWorker from "../../helpers/AjaxWorker";
import DuPhotoViewer from "../DuPhotoViewer";
import DuInput from "../DuInput";
import RatingBar from "../RatingBar";

export default {
  name: "ApplyCell",
  components: {RatingBar, DuInput, DuPhotoViewer, IconsWorker},
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const state = reactive({
      data: props.data,
      isOpen: true,
      infos: [],
      photos: [],
    });
    const createInfoCells = () => {
      state.infos = [];
      state.infos.push({ key: 'الاسم', value: state.data["name"], icon: 'facebookF'});
      state.infos.push({ key: 'البريد', value: state.data["email"], icon: 'facebookF'});
      state.infos.push({ key: 'المنطقة', value: state.data["area"], icon: 'facebookF'});
      state.infos.push({ key: 'انستجرام', value: state.data["instagram"], icon: 'facebookF', onClick: ()=> {
          window.open("https://instagram.com/" + state.data["instagram"], '_blank').focus();
      }});
      state.infos.push({ key: 'رابط الخرائط', value: state.data["google_map_url"], icon: 'facebookF', onClick: ()=> {
          window.open(state.data["google_map_url"], '_blank').focus();
      }});
      state.infos.push({ key: 'التصريح', value: state.data["is_need_permission"] === 1 ? 'يتطلب' : 'لا يتطلب', icon: 'facebookF'});
      state.infos.push({ key: 'تاريخ الطلب', value: state.data["date"], icon: 'facebookF'});
    }
    onMounted(() => {
      createInfoCells();
      state.photos = state.data.photos.split(',');
    });
    const getMainCelStyle = () => {
      let _style = {};
      if(state.data.is_eccept === 0) {
        _style.background = "rgba(255, 0, 83, 0.01)";
      }
      return _style;
    }
    const open = () => {
      state.isOpen = !state.isOpen;
    }
    const getPhotos = () => {
      const rootUrl = new AjaxWorker().url + '/media/';
      const photos = [];
      state.data.photos.split(',').forEach((photo) => {
        photos.push(
            rootUrl + photo
        );
      });
      return photos;
    }
    const changeApplyState = (e) => {
      new AjaxWorker().request("/transactions.php?action=acceptApply&id=" + state.data.id + "&state=" + e.value).then((response) => {
        console.log(response);
      });
    };
    const createPhotoLink = (serial, width=null, height=null) => {
      let link = new AjaxWorker().url + '/media/' + serial;
      if(width !== null && height !== null)
        link = link + '?w=' + width.toString();
      return link;
    }
    return {
      changeApplyState,
      getMainCelStyle,
      getPhotos,
      open,
      state,
      createPhotoLink,
    }
  }
}
</script>

<style scoped>
.ApplyCell{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  padding: 25px 0px;
  gap: 25px;
  text-align: start;
}

.ApplyCell .thumb{
  width: 120px;
  height: 120px;
  background-position: center;
  background-size: cover;
  background-color: #000;
  border-radius: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.ApplyCell .informationSide{
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-top: 30px;
  row-gap: 10px;
}

.ApplyCell .placeName{
  font-size: 18px;
  font-weight: bold;
}

.ApplyCell .rating{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.ApplyCell .catAndArea{
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  place-items: end;
  place-content: center;
  gap: 10px;
}


.ApplyCell .cat{
  font-size: 18px;
  color: #eee;
  font-weight: bold;
}

.ApplyCell .area{
  font-size: 16px;
  color: #fff;
}

.ratingArea {
  place-self: start
}

@media only screen and (max-width: 600px) {
  .ApplyCell {
    gap: 10px;
  }

  .ApplyCell .thumb {
    width: 80px;
    height: 80px;
  }

  .ApplyCell .informationSide{
    margin-top: 10px;
  }
  .ApplyCell .placeName{
    font-size: 15px;
  }
  .ApplyCell .cat{
    padding: 0;
    font-size: 14px;
  }
  .ApplyCell .rating{
    gap: 0px;
  }

  .ApplyCell .cat{
    font-size: 16px;
  }

  .ApplyCell .area{
    font-size: 14px;
  }

}

</style>