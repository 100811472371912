<template>
  <div class="contactUsMessage" v-if="!state.isRemoved">
    <div class="messageInterface">
      <div class="thumb" @click="openMessage()"></div>
      <div class="info" @click="openMessage()">
        <div class="name">{{ data.name }}</div>
        <div class="timeAbout"> منذ <TimeAbout :time-stamp="state.data.date"/></div>
        <div class="email">{{ data.email }}</div>
      </div>
      <div class="optionsButton" @click="(e) => openOptionsMenu(e)">
        <IconsWorker icon="menuF" color="#000" width="20"/>
      </div>
    </div>
    <div class="messageBody" v-if="state.isMessageOpen">{{ data.message }}</div>
  </div>
</template>

<script>
import IconsWorker from "../IconsWorker";
import {reactive} from "vue";
import DuContextMenu from "../plugins/duContextMenu/duContextMenu";
import DuConfirmDialog from "../plugins/duConfirmDialog/duConfirmDialog";
import FixedNotification from "../plugins/fixedNotifications/FixedNotification";
import AjaxWorker from "../../helpers/AjaxWorker";
import router from "../../router";
import myAccountAsyncStore from "../../store/myAccountAsyncStore";
import TimeAbout from "./TimeAbout";

export default {
  name: "ContactUsMessagesCp",
  components: {IconsWorker, TimeAbout},
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  setup(props) {
    const state = reactive({
      isMessageOpen: false,
      data: props.data,
      isRemoved: false,
    });
    const openOptionsMenu = (e) => {
      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "delete"){
            DuConfirmDialog.create({
              title: "هل تريد حذف هذه الرسالة ؟",
              description: "",
              confirmColor: "red",
              confirmText: "حذف",
              cancelText: "لا تراجع",
              onConfirm: () => {
                state.isRemoved = true;
                FixedNotification.create({
                  decription: "تم حذف الرسالة",
                  icon: "delete",
                  color: 'green',
                  hideAfter: 3000
                });
                new AjaxWorker().request('/transactions.php?action=deleteContactUsMessage', {
                  id: state.data.id,
                }).then((res) => {
                  console.log(state.data.id);
                  console.log(res);
                });
              }
            });
          }
        },
        options: [
          { title: 'حذف', key: 'delete', icon: 'delete' },
        ],
      });
    }
    const openMessage = () => {
      state.isMessageOpen = !state.isMessageOpen;
    }
    return {
      state,
      openOptionsMenu,
      openMessage,
    }
  }
}
</script>

<style scoped>
.contactUsMessage{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
}
.messageInterface{
  width: available;
  display: flex;
  padding: 10px 20px;
  flex-direction: row;
  text-align: start;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.messageInterface .thumb{
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #eee;
  background-image: url("../../assets/images/defaultUserThumb.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #ccc;
}

.messageInterface .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  flex: 1;
}

.messageInterface .info .name{
  font-size: 16px;
  color: #000;
}
.messageInterface .info .email{
  font-size: 16px;
  color: #888;
}
.messageInterface .optionsButton{
  padding: 10px;
  background: #ccc;
  border-radius: 100px;
  cursor: pointer;
}

.messageInterface .optionsButton:hover{ opacity: 0.8; }
.messageInterface .optionsButton:active{ opacity: 0.5; }

.messageBody{
  width: available;
  margin-inline-start: 90px;
  text-align: start;
  background: #eee;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  font-size: 15px;
}

.timeAbout{
  font-size: 13px;
  color: #555;
}
</style>