<template>
  <router-link class="outCat" :style="getCatThumbStyle()" :to="getLink()">
    <div class="chefon">
      <div class="ouCatTitle">{{data.title}}</div>
    </div>
  </router-link>
</template>

<script>
import {reactive} from "vue";
import AjaxWorker from "../../helpers/AjaxWorker";
import {useRoute} from "vue-router";

export default {
  name: "OutCat",
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  setup(props) {
    const state = reactive({
      data: props.data,
    });
    const route = useRoute();
    const getCatThumbStyle = () => {
      const _style = {};
      _style.backgroundImage = "url('" + new AjaxWorker().url + "/media/" + state.data.image + "')";
      return _style;
    }

    const getLink = () => {
      if(route.path.includes("/categories/city")) {
        return '/Places?area=' + route.params.area + "&cat=" + state.data.id;
      } else if(route.path.includes("areas")) {
        return '/Places?area=' + state.data.id;
      }
      return '/places?cat=' + state.data.id;
    }
    return {
      state,
      getLink,
      getCatThumbStyle,
    }
  }
}
</script>

<style scoped>
.outCat{
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 23%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px #000;
}
.outCat .chefon{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 2px #000;
}

@media only screen and (max-width: 600px) {
  .outCat {
    height: 150px;
    width: 100%;
    margin: 0%;
    margin-bottom: 20px;
  }
}
</style>