<template>
  <div class="commentCel" v-show="!state.isRemoved">
    <div class="userThumb" :style="getUserThumbStyle()"></div>
    <div class="infoSide">
      <div class="nameAndDate">
        <div class="name">{{ getModelData().name }}</div>
        <div class="timeAbout"><TimeAbout :time-stamp="state.data.date"/></div>
        <router-link :to="'/placeInfo/' + state.data.itemData.id" class="onItem" v-if="Object.keys(state.data.itemData).length > 0">
          على <b>{{state.data.itemData.place_name}}</b>
        </router-link>
      </div>
      <div class="email" v-if="getModelData().is_guest">{{ getModelData().email }}</div>
      <RatingBar :init-rate="getModelData().rating" class="ratingArea" icons-width="12" :read-only="true"/>
      <div class="commentContent" @click="() => state.isOptionsShowed = !state.isOptionsShowed">{{ getModelData().comment }}</div>
      <div class="optionsArea" v-show="state.isOptionsShowed">
        <div class="option" @click="deleteComment()">
          <IconsWorker icon="delete" width="15" class="icon"/>
          <div class="key">حذف</div>
        </div>

        <div class="option" @click="markCommentAsPosted()" v-if="!getModelData().is_posted">
          <IconsWorker icon="cloudTrue" width="15" class="icon"/>
          <div class="key">نشر</div>
        </div>
      </div>
    </div>
    <div class="new" v-if="!getModelData().is_posted"></div>
    <div class="optionsButton" @click="(e) => showOptionsMenu(e)">
      <IconsWorker icon="menuF" width="20"/>
    </div>
  </div>

</template>

<script>
import {onMounted, reactive} from "vue";
import RatingBar from "../RatingBar";
import DuButton from "../DuButton";
import IconsWorker from "../IconsWorker";
import DuConfirmDialog from "../plugins/duConfirmDialog/duConfirmDialog";
import AjaxWorker from "../../helpers/AjaxWorker";
import FixedNotification from "../plugins/fixedNotifications/FixedNotification";
import myAccountAsyncStore from "../../store/myAccountAsyncStore";
import TimeAbout from "./TimeAbout";
import DuContextMenu from "../plugins/duContextMenu/duContextMenu";
import router from "../../router";

export default {
  name: "OutCommentControlPanel",
  components: {TimeAbout, IconsWorker, RatingBar},
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const state = reactive({
      data: props.data,
      isOptionsShowed: false,
      isRemoved: false,
    });
    onMounted(() => {
      console.log(myAccountAsyncStore.getters.getCredentials);
    });
    const getModelData = () => {
      const _data = {
        thumb: null,
        is_guest: false,
      };
      if(state.data.is_guest) {
        _data.name = state.data.anonymos_name;
        _data.is_guest = true;
        _data.email = state.data.anonymos_email;
      }
      _data.rating = state.data.rating;
      _data.is_posted = state.data.is_posted;
      _data.comment = state.data.comment;
      return _data;
    }
    const getUserThumbStyle = () => {
      return {
        backgroundImage: `url('${require('@/assets/images/defaultUserThumb.png')}')`,
        'background-position': 'center 170%',
        'background-size': '90%',
      };
    }
    const markCommentAsPosted = () => {
      state.data.is_posted = true;
      FixedNotification.create({
        decription: "تم نشر التعليق",
        icon: "cloudTrueF",
        color: 'green',
        hideAfter: 3000
      });
      new AjaxWorker().request('/transactions.php?action=markCommentAsPosted', {
        id: state.data.id,
      }).then((res) => {
        console.log(res);
        state.data.is_posted = true;
      });
    };
    const deleteComment = () => {
      DuConfirmDialog.create({
        title: 'سيتم حذف التعليق',
        description: 'هل تريد الإستمرار ؟',
        confirmColor: 'red',
        confirmText: 'نعم',
        cancelText: 'لا',
        onConfirm: () => {
          state.isOptionsShowed = false;
          state.isRemoved = true;

          FixedNotification.create({
            decription: "تم إزالة التعليق",
            icon: "delete",
            color: 'green',
            hideAfter: 3000
          });

          new AjaxWorker().request('/transactions.php?action=deleteComment', {
            id: state.data.id,
          }).then((res) => {
            console.log(res);
          });
        }
      });
    };
    const demo = () => {
      return myAccountAsyncStore.getters.getCredentials;
    }


    const showOptionsMenu = (e) => {

      const _options = [];

      _options.push({ title: 'حذف', key: 'delete', icon: 'delete' });
      //_options.push({ title: 'عرض المنشور', key: 'showPost', icon: 'news' });

      if(!getModelData().is_posted) {
        _options.push({ title: 'نشر', key: 'markCommentAsPosted', icon: 'done' });
      }

      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "markCommentAsPosted") {

            markCommentAsPosted();

          } else if(key === "showPost") {

            router.push("/controlPanel/applys?id=" + state.data.post);

          } else if(key === "delete") {

            deleteComment();

          }
        },
        options: _options,
      });
    }



    return {
      state,
      getModelData,
      markCommentAsPosted,
      showOptionsMenu,
      deleteComment,
      getUserThumbStyle,
    }
  }
}
</script>

<style scoped>
.commentCel{
  width: available;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: start;
  border-bottom: 1px solid #ccc;
}
.commentCel .userThumb{
  width: 60px;
  height: 60px;
  background: #ccc;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.commentCel .infoSide{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.commentCel .nameAndDate{
  gap: 10px;
  display: flex;
  flex-direction: row;
  color: #000;
  align-items: center;
}

.commentCel .nameAndDate .name{
  font-size: 15px;
}
.commentCel .email{
  font-size: 15px;
}

.commentCel .nameAndDate .timeAbout{
  font-size: 13px;
  color: #555;
}

.commentCel .commentContent{
  font-size: 18px;
  color: #000;
  margin-top: 10px;
  cursor: pointer;
}
.commentCel .optionsArea{
  width: available;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding: 10px 0;
  gap: 20px;
  cursor: pointer;
}

.commentCel .optionsArea .option{
  width: available;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.commentCel .optionsArea .option:hover{
  opacity: 0.5;
}

.commentCel .optionsArea .option .icon{
  margin-top: 2px;
}

.onItem{
  color: #520B73;
  font-size: 13px;
}

.new{
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 100px;
  margin-top: 10px;
}

.ratingArea{
  margin: 5px 0;
  gap: 0px;
}

.optionsButton{
  border-radius: 100%;
  padding: 7px;
  background: #ccc;
  margin: 0 10px;
  width: 20px;
  height: 20px;
}

.optionsButton:hover{ opacity:  0.8; }
.optionsButton:active{ opacity:  0.5; }
</style>