<template>
  {{state.timeAsText}}
</template>

<script>
import {onMounted, reactive, watch} from "vue";

export default {
  name: "TimeAbout",
  props: {
    timeStamp: {
      type: Number
    },
  },
  setup(props) {
    const state = reactive({
      timeAsText: '',
      currentTime: new Date().getTime() / 1000,
    });
    onMounted(() => {
      setInterval(() => {
        state.currentTime = new Date().getTime() / 1000;
      }, 1000);
    });
    watch(() => state.currentTime, () => {
      let timeDifference = state.currentTime - props.timeStamp;
      state.timeAsText = getTimeAsText(timeDifference);
    });

    const getTimeAsText = (timeDifference) => {
      let dateInstance = new Date(1970, 0, 1, 0, 0, 0, 0);
      let returnedTimeAbout = '';
      dateInstance.setSeconds(timeDifference);



      if (timeDifference < 10) {
        returnedTimeAbout = 'الأن'
      } else if (timeDifference <= 60) {
        const count = dateInstance.getSeconds();
        returnedTimeAbout = 'ثوان'
        if( timeDifference > 3)
          returnedTimeAbout = count.toString() + " " + 'ثواني';
        if( timeDifference > 10)
          returnedTimeAbout = count.toString() + " " + 'ثانية';
      } else if (timeDifference <= 3600) {

        const count = dateInstance.getMinutes();
        if( count > 0)
          returnedTimeAbout = "دقيقة";
        if( count > 1)
          returnedTimeAbout = "دقيقتين"
        if( count > 2)
          returnedTimeAbout = count.toString() + " " +  "دقائق";
        if( count > 10)
          returnedTimeAbout = count.toString() + " " +  "دقيقة";

      } else if (timeDifference <= 86400) {

        const count = dateInstance.getHours();

        if( count > 0)
          returnedTimeAbout = "ساعة";
        if( count > 1)
          returnedTimeAbout = "ساعتين";
        if( count > 2)
          returnedTimeAbout = count.toString() + " " +  "ساعات";
        if( count > 10)
          returnedTimeAbout = count.toString() + " " +  "ساعة";

      } else if (timeDifference <= 2629743) {
        const count  = parseInt(timeDifference / (60*60*24));

        if( count >= 0)
          returnedTimeAbout = "يوم";
        if( count > 1)
          returnedTimeAbout = "يومان";
        if( count > 2)
          returnedTimeAbout = count.toString() + " " + "أيام";
        if( count > 10)
          returnedTimeAbout = count.toString() + " " + "يوم";

      } else if (timeDifference <= 1866240000) {
        const count  = parseInt(timeDifference / (60*60*24*30));

        if (count >= 0)
          returnedTimeAbout = "شهر";
        if (count > 1)
          returnedTimeAbout = "شهرين";
        if (count > 2)
          returnedTimeAbout = count.toString() + " " + "أشهر";
        if (count > 10)
          returnedTimeAbout = count.toString() + " " + "شهر";

      } else if (timeDifference > 1866240000) {

        const count  = parseInt(timeDifference / (60*60*24*30*12));

        if (count >= 0)
          returnedTimeAbout = "عام";
        if (count > 1)
          returnedTimeAbout = "عامان"
        if (count > 2)
          returnedTimeAbout = count.toString() + " " + "أعوام";
        if (count > 10)
          returnedTimeAbout = count.toString() + " " + "عام";

      } else {
        returnedTimeAbout = 'مدة';
      }
      return returnedTimeAbout
    }

    return {
      state,
    };
  }
}
</script>

<style scoped>

</style>