<template>
  <div class="ratingBarHolder" @mouseleave="setHoverAsSelectedRate()">
    <div class="ratingCel" v-for="index in [1,2,3,4,5]" @click="setRate(index)">
      <IconsWorker icon="starF" :color="getRateIconColor(index)" :width="iconsWidth" @mouseover="setHoveredRate(index)" :style="getStarStyle()"/>
    </div>
  </div>
</template>

<script>
import IconsWorker from "./IconsWorker";
import {reactive} from "vue";
export default {
  name: "RatingBar",
  components: {IconsWorker},
  props: {
    iconsWidth: {
      type: Number,
      default: 20
    },
    onSet: {
      type: Function,
      default: () => {},
    },
    initRate: {
      type: Number,
      default: 10
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props){
    const state = reactive({
      minValue: 0,
      maxValue: 10,
      hoveredRate: props.initRate,
      selectedRate: props.initRate,
    });
    const setHoveredRate = (index) => {
      if(!props.readOnly) {
        state.hoveredRate = index * 2;
      }
    }
    const setRate = (index) => {
      if(!props.readOnly) {
        state.selectedRate = index * 2;
        props.onSet(index * 2);
      }
    }
    const setHoverAsSelectedRate = () => {
      if(!props.readOnly) {
        state.hoveredRate = state.selectedRate;
      }
    }
    const getRateIconColor = (index) => {
      if((index * 2) <= Math.ceil(state.hoveredRate)) {
        return "#DEC215";
      }
      return "#ccc";
    }

    const getStarStyle = () => {
      const _style = {};
      if(!props.readOnly) {
        _style.cursor = 'pointer';
      }
      return _style;
    }

    return {
      state,
      setHoverAsSelectedRate,
      setHoveredRate,
      getStarStyle,
      setRate,
      getRateIconColor,
    };
  }
}
</script>

<style scoped>
  .ratingBarHolder{
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
    justify-content: start;
  }
  .ratingCel{
    width: 20px;
    height: 20px;
  }
</style>