<template>
    <div class="duPhotoCel" :style="getStyle()" @click="openLightBox()">
      <IconsWorker icon="loading" width="20" v-if="state.isLoading"/>
      <img :src="thumb" @load="onLoad()" v-if="state.isLoading"/>
      <div class="theImageElement" :style='{backgroundImage: `url(${thumb})`}'></div>
    </div>

    <div class="lightBox" v-if="state.isLightBoxOpen" @click="openLightBox()">
      <div class="close"><IconsWorker icon="closeF" width="30" color="#fff"/></div>
    </div>

    <div class="photoShow" v-if="state.isLightBoxOpen">
      <img :src="state.fullImage" class="imageInTheLightBox" :style="getImageStyle()"/>
      <div class="arrows">
        <div class="arrowCell" @click="moveNextOrPre('next')"><IconsWorker icon="rightRowF" :width="25"/></div>
        <div class="arrowCell" @click="moveNextOrPre('pre')"><IconsWorker icon="leftRowF" :width="25"/></div>
      </div>
      <div class="miniThumbs">
          <div class="cell" v-for="(imageObj, index) in getThumbs()" :key="'lightBoxthumb' + index"  @click="selectImage(imageObj)" :style="getStyleOfThumbSquire(imageObj)"></div>
      </div>
    </div>

</template>

<script>
import {onMounted, reactive, watch} from "vue";
import IconsWorker from "./IconsWorker";

export default {
  name: "DuPhotoViewer",
  components: {IconsWorker},
  props: {
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    thumb: {
      type: String,
      default: null
    },
    fullImage: {
      type: String,
      default: null
    },
    listOfLinks: {
      type: Object,
      default: [], // example { thumb: 'https://thumbImageLink, fullImage: "https:/fullimageLink" .... } fullImage is most importent
    }
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      isLightBoxOpen: false,
      id: Math.random().toLocaleString(16),
      fullImage: props.fullImage,
    });
    const getStyle = () => {
      const _style = {};
      _style.width = props.width;
      _style.height = props.height;

      if(!props.width.toString().includes("%")) {
        _style.width = _style.width + "px";
      }
      if(!props.height.toString().includes("%")) {
        _style.height = _style.height + "px";
      }
      return _style;
    };
    const onLoad = () => {
      state.isLoading = false;
    };
    const getImageStyle = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      return {
        maxWidth: screenWidth + 'px',
        maxHeight: screenHeight + 'px',
      }
    };
    const openLightBox = () => {
      state.isLightBoxOpen = !state.isLightBoxOpen;
    }
    const getThumbs = () => {
      const links = props.listOfLinks;
      return links;
    }
    const selectImage = (imageObj) => {
      state.fullImage = imageObj.fullImage;
    }
    const moveNextOrPre = (direction) => {
      let currentIndex = 0;
      const listOfThumbs = getThumbs();
      for(let i=0; i< listOfThumbs.length; i++) {
        if(listOfThumbs[i].fullImage === state.fullImage) {
          currentIndex = i;
        }
      }


        if(direction === "next") {
          currentIndex = currentIndex + 1;
        } else if(direction === "pre") {
          currentIndex = currentIndex - 1;
        } else {
          const key = event.key; // "ArrowRight", "ArrowLeft", "ArrowUp", or "ArrowDown"
          if (key === "ArrowRight") {
            currentIndex = currentIndex + 1;
          } else if (key === "ArrowLeft") {
            currentIndex = currentIndex - 1;
          }
        }


      if(currentIndex < 0) {
        state.fullImage = listOfThumbs[listOfThumbs.length - 1].fullImage;
      } else if(currentIndex >= listOfThumbs.length) {
        state.fullImage = listOfThumbs[0].fullImage;
      } else {
        state.fullImage = listOfThumbs[currentIndex].fullImage;
      }

    }
    watch(() => state.isLightBoxOpen, () => {

      if(state.isLightBoxOpen) {
        document.addEventListener('keydown', moveNextOrPre);
      } else {
        document.removeEventListener('keydown', moveNextOrPre);
      }

    });
    const getStyleOfThumbSquire = (imageObj) => {
      const _style = {};
      _style.backgroundImage = 'url(' + imageObj.thumb + ')';
      if(imageObj.fullImage === state.fullImage) {
        _style.border = "1px solid #ccc";
      }
      return _style;
    }

    return {
      selectImage,
      openLightBox,
      getStyleOfThumbSquire,
      getThumbs,
      getImageStyle,
      getStyle,
      moveNextOrPre,
      onLoad,
      state,
    }
  }
}
</script>

<style scoped>
.duPhotoCel{
  background-color: #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.theImageElement{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.lightBox{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  z-index: 100;
}
.lightBox .close{
  padding: 10px;
  background: red;
  position: absolute;
  margin: 50px;
  border-radius: 100px;
  cursor: pointer;
}
.lightBox .close:hover{opacity: 0.8;}
.lightBox .close:active{opacity: 0.5;}
.photoShow{
  background: white;
  margin: auto;
  top:0;
  bottom:0;
  left: 0;
  right: 0;
  box-shadow: 0 0 10px #000;
  position: fixed;
  z-index: 110;
  display: flex;
  flex-direction: column;
  width: min-content;
  height: min-content;
  max-height: 100%;
  max-width: 100%;
}


.photoShow .miniThumbs{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  overflow-x: auto;
}
.photoShow .miniThumbs::-webkit-scrollbar { display: none;}

.photoShow .miniThumbs .cell{
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  background-color: #999;
  border-radius: 10px;
  box-shadow: 0 0 10px #333;
  margin-inline-start: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.imageInTheLightBox{
  flex: 1;
}

.arrows{
  width: 80%;
  left: 0;
  bottom: 0;
  top: 0;
  height: 70px;
  display: flex;
  flex-direction: row;
  position: fixed;
  margin: auto 10%;
  z-index: 200;
  justify-content: space-between;
}

.arrows .arrowCell{
  padding: 15px 15px;
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  place-items: center;
  place-content: center;
}

.arrowCell:hover{
  background: #fff;
}
.arrowCell:active{
  background: #ccc;
}

@media only screen and (max-width: 600px) {
  .arrows .arrowCell {
    padding: 5px;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.8);
  }
  .photoShow .miniThumbs{
    display: none;
  }
  .photoShow .miniThumbs .cell{
    width: 50px;
    height: 50px;
    display: none;
  }
  .lightBox .close{
    padding: 5px;
    margin: 20px;
  }
}
</style>